<template>
  <div class="home">
    <top-nav @menu_data="getMenuData" :isARClogo="isARClogo" />
    <div class="part1">
      <div class="background-pic">
        <img src="./../../assets/imgs/app/homepage/backg11.png" class="part1Pic"/>
        <img src="./../../assets/imgs/app/homepage/backg12.png" class="part2Pic"/>
        <img src="./../../assets/imgs/pc/homepage/playMediaIcon.png" alt="" class="playButton" @click.stop="openVideo">
      </div>
      <div class="text11">
        <div class="part1Title">
          {{ $t("home.make_project") }} <span>{{ $t("home.effortless") }}</span>
        </div>
        <div class="part1Content">
          {{ $t("home.welcome") }}
        </div>
        <div class="part1Buttons">
          <div class="part1Button" :class="[isID?'IDpart1Button':'']">
            <a v-if="!isID" href=" " onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
                return false;">
              <div class="part1ButtonText">{{ $t("home.book_a_demo") }}<img src="./../../assets/imgs/app/arcanite/vector.png" class="vector"></div>
            </a>
            <a v-else href=" " onclick="Calendly.showPopupWidget('https://calendly.com/erni-yap');
                return false;">
              <div class="part1ButtonText">{{ $t("home.book_a_demo") }}<img src="./../../assets/imgs/app/arcanite/vector.png" class="vector"></div>
            </a>
          </div>
          <div class="totalButtonSection">
            <div v-if="isID" class="idWhatsApp" @click="callWhatsApp" style="display:none">
              <img src="./../../assets/imgs/pc/homepage/Arc-log-whatsapp.png" alt="" class="whatsAppIcon">
            </div>
          </div>
        </div>
        <div :class="{videoMain:isOpenVideo}" v-if="isOpenVideo">
          <div class="videoPosi">
            <div style="padding:56.25% 0 0 0;position:relative;z-index:999;" class="videoContent">
              <iframe src="https://player.vimeo.com/video/716632631?h=c391abb874&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Welcome to Arcanite">
              </iframe>
              <div class="close-icon" @click.prevent="closeVideo()">
                Close
                <!-- <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.4141 4.6193L11.0001 10.0334L5.586 4.6193C5.44254 4.49051 5.28144 4.42611 5.10269 4.42611C4.92394 4.42611 4.76627 4.49417 4.62969 4.6303C4.4931 4.76642 4.42504 4.92409 4.4255 5.1033C4.42596 5.28251 4.49035 5.44361 4.61869 5.58661L10.0328 11.0007L4.61869 16.4147C4.4326 16.6008 4.37165 16.8229 4.43581 17.0809C4.49998 17.339 4.65765 17.5001 4.90881 17.5642C5.15998 17.6284 5.38548 17.5674 5.58531 17.3814L10.9994 11.9673L16.4134 17.3814C16.5569 17.5101 16.718 17.5745 16.8967 17.5745C17.0755 17.5745 17.2332 17.5065 17.3697 17.3704C17.5063 17.2342 17.5744 17.0766 17.5739 16.8974C17.5735 16.7181 17.5091 16.557 17.3807 16.414L11.9667 11L17.3807 5.58592C17.5668 5.39984 17.6278 5.17778 17.5636 4.91974C17.4995 4.66169 17.3384 4.50059 17.0803 4.43642C16.8223 4.37226 16.6002 4.43321 16.4141 4.6193Z" fill="#FFFFFF"/>
                </svg> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part2RecoginizedIcons">
      <div class="part2-topcontent">
        <trusted></trusted>
      </div>
      <div class="part2-midcontent">
        <featuredIn></featuredIn>
      </div>
      <div class="part2-bottomcontent">
          <recognised></recognised>
      </div>
    </div>
    <div class="part25ArcaniteFor">
      <div class="tab-differentCustomer">
        <div class="title">{{ $t("home.who_is") }} <span>{{ $t("home.arcanite") }}</span> {{ $t("home.for") }}?</div>
        <el-tabs type="border-card" class="tabsstyle" > 
          <el-tab-pane :label="$t('home.for_agent')">
            <div class="content">
              <div class="subtitle">{{ $t("home.see_how_jack") }}</div>
              <div class="meetPerson">
                <img src="./../../assets/imgs/pc/homepage/meetJack.png" alt="" class="meetPerson-pic" @click.stop="openJackVideo">
                <img src="./../../assets/imgs/pc/homepage/playMediaIcon.png" alt="" class="playButton" @click.stop="openJackVideo">
              </div>
              <div :class="{videoMain:isOpenJackVideo}" v-if="isOpenJackVideo">
                <div class="videoPosi">
                  <div style="padding:56.25% 0 0 0;position:relative;z-index:999;" class="videoContent">
                    <iframe src="https://player.vimeo.com/video/730175869?h=eca5b740be&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Welcome to Arcanite">
                    </iframe>
                    <div class="close-icon" @click.prevent="closeJackVideo()">
                      Close
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('home.for_developer')">
            <div class="content">
              <div class="subtitle">Learn how Victor, a busy property developer manages all of his projects effortlessly with Arcanite</div>
              <div class="meetPerson">
                <img src="./../../assets/imgs/pc/homepage/meetVictor.png" alt="" class="meetPerson-pic" @click.stop="openVictorVideo">
                <img src="./../../assets/imgs/pc/homepage/playMediaIcon.png" alt="" class="playButton" @click.stop="openVictorVideo">
              </div>

              <div :class="{videoMain:isOpenVictorVideo}" v-if="isOpenVictorVideo">
                <div class="videoPosi">
                  <div style="padding:56.25% 0 0 0;position:relative;z-index:999;" class="videoContent">
                    <iframe src="https://player.vimeo.com/video/731940173?h=94b7617d9d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Welcome to Arcanite">
                    </iframe>
                    <div class="close-icon" @click.prevent="closeVictorVideo()">
                      Close
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="effortlessButton">
          <a v-if="!isID" href=" " onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
              return false;">
            <div class="part1ButtonText" :class="[isID?'IDpart1ButtonText':'']">{{ $t("home.book_a_demo") }}
              <svg class="vector" width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.7463 9.375H3.125C2.9425 9.375 2.79271 9.43354 2.67563 9.55062C2.55854 9.66771 2.5 9.8175 2.5 10C2.5 10.1825 2.55854 10.3323 2.67563 10.4494C2.79271 10.5665 2.9425 10.625 3.125 10.625H14.7463L10.1756 15.1756C10.0585 15.306 10 15.4558 10 15.625C10 15.7942 10.0619 15.9406 10.1856 16.0644C10.3094 16.1881 10.4558 16.25 10.625 16.25C10.7942 16.25 10.944 16.1915 11.0744 16.0744L16.6994 10.4494C16.8165 10.319 16.875 10.1692 16.875 10C16.875 9.83083 16.8165 9.68104 16.6994 9.55062L11.0744 3.92563C10.944 3.80854 10.7942 3.75 10.625 3.75C10.4558 3.75 10.3094 3.81187 10.1856 3.93562C10.0619 4.05937 10 4.20583 10 4.375C10 4.54417 10.0585 4.69396 10.1756 4.82437L14.7463 9.375Z" fill="#FFFFFF"/>
              </svg>
              <!-- <img src="./../../assets/imgs/app/arcanite/vector.png" class="vector"> -->
            </div>
          </a>
          <a v-else href=" " onclick="Calendly.showPopupWidget('https://calendly.com/erni-yap');
              return false;">
            <div class="part1ButtonText" :class="[isID?'IDpart1ButtonText':'']">{{ $t("home.book_a_demo") }}
              <svg class="vector" width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.7463 9.375H3.125C2.9425 9.375 2.79271 9.43354 2.67563 9.55062C2.55854 9.66771 2.5 9.8175 2.5 10C2.5 10.1825 2.55854 10.3323 2.67563 10.4494C2.79271 10.5665 2.9425 10.625 3.125 10.625H14.7463L10.1756 15.1756C10.0585 15.306 10 15.4558 10 15.625C10 15.7942 10.0619 15.9406 10.1856 16.0644C10.3094 16.1881 10.4558 16.25 10.625 16.25C10.7942 16.25 10.944 16.1915 11.0744 16.0744L16.6994 10.4494C16.8165 10.319 16.875 10.1692 16.875 10C16.875 9.83083 16.8165 9.68104 16.6994 9.55062L11.0744 3.92563C10.944 3.80854 10.7942 3.75 10.625 3.75C10.4558 3.75 10.3094 3.81187 10.1856 3.93562C10.0619 4.05937 10 4.20583 10 4.375C10 4.54417 10.0585 4.69396 10.1756 4.82437L14.7463 9.375Z" fill="#FFFFFF"/>
              </svg>
              <!-- <img src="./../../assets/imgs/app/arcanite/vector.png" class="vector"> -->
            </div>
          </a>
        </div>
      </div>
    </div>


    <div class="testimon">
      <div class="title">
        {{ $t("testimonials.client_testimonials") }}
      </div>
      <!-- <transition name='fade'>
            <img :src="require('./../../assets/imgs/app/homepage/p5'+item+'.png')" :key="'middle'+part5MiddleNumber"/>
      </transition> -->
      <swiper class="swiper" :options="swiperOption" :auto-update="true">
        <swiper-slide v-for="(item,index) in cardInfo" :key='item.companyName'>
          <div class="main-card-section">
            <div class="brand-name-section">
              <div class="logo">
                <img class="logo-pic" :src="item.logo" alt="">
              </div>
              <div class="company-name">
                <div class="name">{{item.companyName}}</div>
                <div class="author">{{item.author}}</div>
                <div class="star-section">
                  <img src="./../../assets/imgs/app/homepage/star.png" class="star" alt="">
                </div>
              </div>
              
            </div>
            <div class="comment-section" style="white-space: pre-wrap;" v-html="item.comment"></div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" style="bottom:0"></div>
      </swiper>

  

      <!-- <div class="dogs">
        <div v-for="p in part5All" :key="p">
          <span v-if="p==part5MiddleNumber" class="dogBlue">
          </span>
          <span v-else class="dogWhite" @click="changePic(p)">
          </span>
        </div>
      </div> -->
    </div>


    <div class="part33-content">
        <!-- <div class="back-pic"></div> -->
        <div class="part33-eachSection">
          <div class="simplify-control">
            <div class="info-content">
              <div class="info-icon">
                <img src="./../../assets/imgs/pc/homepage/minicons1.png" class="pic" />
              </div>
              <div class="info-title">{{ $t("home.simplify_sales_management") }}</div>
              <div class="info-para">
                {{ $t("home.unified_sales") }}
              </div>
              <div class="line"></div>
              <div class="bullet-points">
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.simple_navigation") }}</div>
                </div>
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.track_individual") }}</div>
                </div>
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.enhanced_channel_communication") }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="simplifySales bottom-pic-container">
            <img src="./../../assets/imgs/app/homepage/simplifySales1.png" class="simplifyPic parallax-item" data-depth="1" />
            <img src="./../../assets/imgs/app/homepage/simplifySales2.png" class="simplifyPic2 parallax-item" data-depth="0.6" />
          </div>
        </div>

        <div class="part33-eachSection">
          <div class="stay-control">
            <div class="info-content">
              <div class="info-icon">
                <img src="./../../assets/imgs/pc/homepage/stayInIcon.png" class="pic" />
              </div>
              <div class="info-title">{{ $t("home.stay_in_control") }}</div>
              <div class="info-para">
                {{ $t("home.simple_to_use") }}
              </div>
              <div class="line"></div>
              <div class="bullet-points">
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.manage_via_arcanite") }}</div>
                </div>
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.stay_up_to_date") }}</div>
                </div>
                <div class="bullet-point">
                  <div class="dot extra-adjust"></div><div class="text">{{ $t("home.control_what_information") }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="stayInControl bottom-pic-container">
            <img src="./../../assets/imgs/app/homepage/stayInControl1.png" class="stayPic parallax-item" data-depth='1' />
            <img src="./../../assets/imgs/app/homepage/stayInControl2.png" class="stayPic1 parallax-item"  data-depth='0.6'/>
            <img src="./../../assets/imgs/app/homepage/stayInControl3.png" class="stayPic2 parallax-item"  data-depth='0.6'/>
          </div>
        </div>

        <div class="part33-eachSection">
          <div class="smart-control">
            <div class="info-content">
              <div class="info-icon">
                <img src="./../../assets/imgs/pc/homepage/smartAutomate.png" class="pic" />
              </div>
              <div class="info-title">{{ $t("home.smart_automated") }}</div>
              <div class="info-para">
                {{ $t("home.less_data_entry") }}
              </div>
              <div class="line"></div>
              <div class="bullet-points">
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.fast_track") }}</div>
                </div>
                <div class="bullet-point">
                  <div class="dot"></div><div class="text">{{ $t("home.simplifies_all_processes") }}</div>
                </div>
                <div class="bullet-point">
                  <div class="dot extra-adjust"></div><div class="text">{{ $t("home.find_contacts") }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="smartAutomated bottom-pic-container">
            <img src="./../../assets/imgs/app/homepage/circle-blue.png" class="smartPic3"/>
            <img src="./../../assets/imgs/app/homepage/smartAutomated1.png" class="smartPic parallax-item"  data-depth='0.8'/>
            <img src="./../../assets/imgs/app/homepage/smartAutomated2.png" class="smartPic1 parallax-item" data-depth='0.6' />
          </div>
        </div>
      </div>

    <!-- <div v-for="i,index in pageContent" :key="'part2'+index" class="part22">
      <div class="part2">
        <img :src="i.minicon" class="pic" />
        <div class="part2Title">
          {{i.title}}
        </div>
        <div class="part2Content">
          {{i.content}}
        </div>
        <div class="part2Line"></div>
        <div class="part2subLine" v-for="j,indexj in i.subLine" :key="'subline'+indexj">
            <div class="dog"></div><div class="text">{{j}}</div>
        </div>
        <div style="width:375px">
          <img :src="i.bottomPic" :style="i.bottomPicSize"/>
        </div>
      </div>
    </div> -->
    <more-features></more-features>
    <footer-nav />
    <menu-bar :isShow="isShow" @changeIsShow="changeShow" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isID:false,
      cardInfo:
      [
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/coronation.png'),
          companyName: 'Coronation',
          author: 'Anita',
          comment: 'I have been using Arcanite for the past 4 months. As a Channel Manager, Arcanite is perfect app to manage channels, share marketing material and maintain stocks. William and his team are professional and helpful through the whole journey. They made onboarding process easy and smooth. Our channels was trained by them which freed my time to focus on selling. Whenever there is an issue, William’s team always have my back. The continuous improvement of functions and UI makes it more user-friendly and powerful selling tool. Highly recommended to everyone in property industry.',
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/creation.png'),
          companyName: 'Creation Homes',
          author: 'Shelby',
          comment: 'Our experience with Arcanite has been seamless from customisation and development to training and on-boarding. The Arcanite team have gone above and beyond to develop the right solution for our business needs and deliver a platform that caters for our ever – changing project and platform requirements. Creation Homes NSW looks forward to our ongoing relationship with Arcanite in transforming how we do business as a project marketer.',
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/mcgrath.png'),
          companyName: 'McGrath Projects',
          author: 'Louise Drummond',
          comment: "The Projects space is a unique one within the Real Estate industry and not many off the shelf CRM's can cater to all the needs of Projects. </br> </br>The team at Arcanite are developing and providing a unique solution to resolve this need, they are working collaboratively with our team to make changes and adjustments to their product. They are hungry for feedback and eager to make their product user friendly, productive and versatile. The IT and admin support from every team member has been excellent.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/quarter.png'),
          companyName: 'QUARTER ACRE',
          author: 'Chirag Balani',
          comment: "We settled with ARC after looking around for an inventory management app for a long time and we are very happy that we decided to proceed with ARC. It is much more then an inventory management app, very easy to use, intuitive and feature are always growing. ARC has a very close-knit team and very supportive. We would highly recommend ARC to anyone looking for an inventory management app.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/sh.png'),
          companyName: 'SH International',
          author: 'Narikki Kudinar-Kwee',
          comment: "ARC has provided SH with greater control and management of our project sales. With its ease of use for both the agent and developer we are able to implement this quickly and without fuss. ARC is now an integral part of all our current and future projects.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/ozy.png'),
          companyName: 'Ozy Homes',
          author: '',
          comment: "Ozyhomes is now using Arcanite as our CRM. We are very impressed with the platform as it has great customer contact management along with backend agent management that supports sales management and precisely assists our business to quantify lead source capture and opportunities. The Arcanite Team behind the platform is supportive and responsive making it easy to integrate this platform into our business.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/tallpopie.png'),
          companyName: 'Tallpopie',
          author: 'Jennifer Hawke',
          comment: "After months of searching for a CRM solution that catered to off-the-plan sales it was great to come across Arcanite. The software provides a hub for all project collateral, advises of real time reservations and sales - ensuring both our internal and external agents are working with the most up to date information. It is refreshing to work with a team who are excited to advance their software and actively engage with the consumer to ensure all needs are being met. We look forward to an ongoing relationship with the Arcanite team, Jane & Harvey in particular are an absolute delight!",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/linic.png'),
          companyName: 'Linic Property Group',
          author: 'Juliet Chen',
          comment: "It is the first time working with Arcanite to produce virtual tours of our off-the-plan apartments and the team has been an absolute pleasure to deal with. They were patient throughout the whole process, the end results of the renders were of high quality, and all the feedback we've received since has been amazing. I highly recommend their VR service, it has proven very beneficial for selling pre-sale properties.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/kmac.png'),
          companyName: 'Kmac Group',
          author: 'Anna Mingoy',
          comment: "We've used some CRMs and Sales Portals before but have always been boxed into whatever process and system the Software Developer has created. Getting help can also be a pain due to some people not being able to explain anything other than in “tech language”. What we love about Arcanite is not only how easy it is to use and navigate their system, but most importantly the team is very accommodating, and they are always available when we need further help.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/liveProperty.png'),
          companyName: 'Live Property Marketing',
          author: 'Manny and Khay',
          comment: "ARC has been an amazing system to use, has halved the time for admin work and saved us a whole salary on an admin assistant. The fact that you can use this on your phone, Ipad and computer is really helpful. It looks sophisticated and it's easy to use. The backend is also amazing as this shows us how the business is doing in sales and the projects. Would rate this a 20/10 if we could.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/family.png'),
          companyName: 'Family First',
          author: 'Lawrence',
          comment: "Arcanite has the best lead generation system. Arcanite has helped grow my business and referral partners. The pre qualification system helps me to manage and prioritise leads. Arcanite is a perfect fit for my business. Highly recommended.",
          // index: 0,
        },
      ],
      isOpenVideo:false,
      isOpenJackVideo:false,
      isOpenVictorVideo:false,
      showMenuFlag: "0",
      isShow: false,
      isARClogo: true,
      part5MiddleNumber:2,
      // part5LeftNumber:1,
      // part5RightNumber:3,
      // part5All:3,
      // pageContent:[
      //   {title:'Simplify Sales Management',
      //     minicon:require('@/assets/imgs/pc/homepage/minicons1.png'),
      //     content:"Arcanite's unified sales portal facilitates the management of all projects and all sales channels from your mobile device.",
      //     subLine:['Simple navigation between projects','Track individual sales channels','Enhanced channel communication'],
      //     bottomPic:require('@/assets/imgs/app/homepage/pic0.png'),
      //     bottomPicSize:{width:'93.4%'}},
      //     {title:'All-in-one',
      //     minicon:require('@/assets/imgs/pc/homepage/minicons2.png'),
      //     content:'Everything you need to track and nurture your relationships with salespeople, buyers, and professional associates. Embedded with sales and marketing functions and VR program.',
      //     subLine:['Facilitates aggregation of external systems','App based software enables all functions in any location','Never miss an update, notification, or sales opportunity'],
      //     bottomPic:require('@/assets/imgs/app/homepage/pic1.png'),
      //     bottomPicSize:{width:'100%'}},
      //     {title:'Stay in Control',
      //     minicon:require('@/assets/imgs/pc/homepage/minicons3.png'),
      //     content:'Simple to use, advanced back-end dashboard. Sort by sales status, book appointments and track performance.',
      //     subLine:['Manage via Arcanite connect admin app','Stay up to date on all project information','Control what information is available on the frontend Arcanite app'],
      //     bottomPic:require('@/assets/imgs/app/homepage/pic2.png'),
      //     bottomPicSize:{width:'100%',marginBottom:'40px',position:'relative',left:'-13%'}},
      //     {title:'Smart & Automated',
      //     minicon:require('@/assets/imgs/app/homepage/minicon4.png'),
      //     content:'Less data entry means more time to make deals. Arcanite automates the task salespeople hate and takes only minutes to learn!',
      //     subLine:['Fast track tedious admin tasks','Simplifies all processes','Find contacts, share information, and reserve properties with one click'],
      //     bottomPic:require('@/assets/imgs/app/homepage/pic3.png'),
      //     bottomPicSize:{width:'100%'}},
      //     ],
      swiperOption: {
          // spaceBetween: 30,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          initialSlide:0,
          autoplay:false,
          loop: true,
          speed:1000,
          // autoplay: {
          //   delay: 15000, //自动切换的时间间隔，单位ms
          //   stopOnLastSlide: false, //当切换到最后一个slide时停止自动切换
          //   // stopOnLastSlide: true, //如果设置为true，当切换到最后一个slide时停止自动切换。
          //   disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。
          //   // reverseDirection: true, //开启反向自动轮播。
          //   // waitForTransition: true, //等待过渡完毕。自动切换会在slide过渡完毕后才开始计时。
          // },
        }
    };
    
  },
  created() {

    if(localStorage.getItem('country')=="id"){
      this.isID=true;
    }else{
      this.isID=false;
    }
    // 自动轮播定时器
    // this.autoTest()
  },
  mounted() {
    if(!this.isID && window && window.HubSpotConversations && window.HubSpotConversations.widget){
      window.HubSpotConversations.widget.remove(); 
    }
    window.addEventListener("scroll", this.handleScroll, true);
    // this.handleScroll();
    // this.handleFirstPara();
    new Swiper ('.swiper-container', {
      loop: true,
      // 如果需要分页器
      pagination: '.swiper-pagination',
      // 如果需要前进后退按钮
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      // 如果需要滚动条
      scrollbar: '.swiper-scrollbar',
    });
  },
  beforeDestroy(){
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    callWhatsApp(){
      let data = "https://api.whatsapp.com/send?phone=6281319386791"
      window.open(data,"_self")
    },
    openVideo(){
      this.isOpenVideo=true;
    },
    closeVideo(){
      this.isOpenVideo=false;
    },
    openJackVideo(){
      this.isOpenJackVideo=true;
    },
    closeJackVideo(){
      this.isOpenJackVideo=false;
    },
    openVictorVideo(){
      this.isOpenVictorVideo=true;
    },
    closeVictorVideo(){
      this.isOpenVictorVideo=false;
    },
    change(number,direction){
      if(number==1 && direction==-1){
        number=this.part5All
      }else if(number==this.part5All && direction==1){
        number=1
      }
      else{
        number=number+direction
      }
      return number
    },
    // part5Right(){
    //   // this.part5LeftNumber=this.change(this.part5LeftNumber,1)
    //   // this.part5RightNumber=this.change(this.part5RightNumber,1)
    //   this.part5MiddleNumber=this.change(this.part5MiddleNumber,1)
    // },
    goRouter(path) {
      this.$router.push(path);
    },
    getMenuData(val) {
      val == "1" ? (this.isShow = true) : (this.isShow = false);
    },
    changeShow() {
      this.isShow = false;
    },
    handleClickDisplay() {
      this.isMove = true;
    },
    changePic(p){
      this.part5MiddleNumber=p
    },
    // 自动轮播定时器
    // autoTest(){
    //   this.test=setInterval(() => {
    //       this.part5Right()
    //   }, 15000);
    // },
  },

  components: {
    "top-nav": (resolve) => require(["./TopNav.vue"], resolve),
    "footer-nav": (resolve) => require(["./FooterNav.vue"], resolve),
    "menu-bar": (resolve) => require(["./MenuBar.vue"], resolve),
    "trusted":(resolve) => require(["./Trusted.vue"], resolve),
    "recognised":(resolve) => require(["./Recognised.vue"], resolve),
    "featuredIn": (resolve) => require(["./FeaturedIn.vue"], resolve),
    "more-features":(resolve) => require(["./MoreFeatures.vue"], resolve),
  },
};
</script>
<style lang="scss" scoped>

.home{
  text-align: center;
  width: 100vw;
  overflow: hidden;
  margin: auto;
  max-width: 428px;
  min-width: 320px;
}

.part1{
  height: 100%;
  margin: auto;
  max-width: 428px;
  min-width: 320px;
  // overflow: hidden;
  // height: 659px;
  // background-color: #1890ff;
  // background: linear-gradient(-6deg, transparent 60px, #1890ff 0);
  margin-top: 80px;
  z-index: 5;
  .background-pic{
    position: relative;
    width: 100vw;
    height: 365px;
    // margin: 0 0 0px 0;
    .playButton{
      cursor: pointer;
      position:absolute;
      width: 28.65px;
      height: 28.69px;
      top:42%;
      left:45%;
      transtion: translate(-50%,-50%);
      z-index:5;
    }
  }
  .part1Pic{
    position: absolute;
    // margin: auto;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    object-fit: cover;
  }
  
  .part2Pic{
    position: absolute;
    width: 234px;
    height: 100%;
    right: 0px;
    top: 30px;
  }
  .text11{
    position: relative;
    // top:-200px;
  }
  .part1Title{
    margin: auto;
    width: fit-content;
    padding:0 26px;
    // height: ;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 30px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
    // white-space: nowrap;
    span{
    height: 72px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 30px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #1890FF;
    }
  }
  .part1Content{
    margin: auto;
    margin-top:10px;
    // width: 323px;
    padding:0 26px 0 26px;
    height: 160px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #314455;;
  }
  .part1Buttons{
    display: flex;
    flex-direction: column;
    margin: 0 26px;
    margin-top: 30px;
    gap: 10px;
  }
  .part1Button{
    width: 155px;
    height: 22px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    border-radius: 5px;
    background-color: #1890FF;
  }
  .IDpart1Button{
    width: 212px;
  }
  .part1ButtonText{
    display: flex;
    height: 22px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 16px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
}

.vector{
    width: 22px;
  height: 22px;
  flex-grow: 0;
  object-fit: contain;
  margin-left: 10px;

}

.part2RecoginizedIcons{
  margin: 40px auto;
  // width: 1210px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items:center;
  gap: 30px;
}

.part25ArcaniteFor{
  max-width: 428px;
  min-width: 320px;
  margin: 100px auto;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  padding: 0px;
  gap: 20px;
  height: 100%;
  

  .title{
    padding:0 26px;
    // margin: auto;
    align-self: flex-start;
    text-align: left;
    // padding: 0 15px;
    flex: none;
    flex-grow: 0;
    // white-space: nowrap;
    width: fit-content;
    // height: 84px;
    font-family: 'Poppins-bold';
    font-style: normal;
    font-size: 36px;
    line-height: 42px;
    color: #062440;
    margin-bottom: 21px;
    span{
      flex: none;
      order: 0;
      flex-grow: 0;
      white-space: nowrap;
      width: 292px;
      height: 84px;
      font-family: 'Poppins-bold';
      font-style: normal;
      font-size: 36px;
      line-height: 42px;
      color: #1890FF;
    }
  }


  .tab-differentCustomer{
    // margin:0 auto;
    // .title
    .el-tabs{
      width: 100%;
      position: relative;
    }
    .subtitle{
      // align-self: flex-start;
      position: relative;
      // left: -15px;
      margin: 0 auto;
      margin-top: 16px;
      margin-bottom: 26px;
      padding: 0 26px;
      font-family: 'Poppins-Medium';
      text-align: left;
      color: #314455;
      min-width: 270px;
      // height: 40px;
      font-size: 14px;
      line-height: 20px;
    }
  }


  .meetPerson{
    position:relative;
    padding: 0 26px;
    // min-width: 300px; 
    // 图片
    .meetPerson-pic{
      width: 100%;
      height: 187px;
      // background: linear-gradient(0deg, rgba(6, 36, 64, 0.2), rgba(6, 36, 64, 0.2));
      border: 1px solid #E5E6EB;
      // background: linear-gradient(0deg, rgba(6, 36, 64, 0.2), rgba(6, 36, 64, 0.2));
      // filter: drop-shadow(0px 4px 4px rgba(6, 36, 64, 0.9));
      border-radius: 20px;
    }
    .playButton{
      cursor: pointer;
      position:absolute;
      width: 28.65px;
      height: 28.69px;
      top:42%;
      left:45%;
      transtion: translate(-50%,-50%);
      z-index:5;
    }
  }
}

.part33-content::before{
  content: " ";
  // text-align: center;
  // top: 50%;
  left: 50%;
  top: -140px;
  transform: translateX(-50%);
  width: 200%;
  height: 150px;
  border-radius: 50%;
  background:#FFFFFF;
  color: #FFFFFF;
  position: absolute;
  // left:0;
  // top: -100px;
}
.part33-content{
  // margin:25px 0;
  background: linear-gradient(180deg, #EEF5FC 0%, #F1F7FE 71.91%, rgba(243, 249, 255, 0) 100%);
  height: 100%;
  width: 100%;
  position: relative;
  // .back-pic{
  //   // display: flex;
  //   // flex-direction: column;
  //   // align-items: center;
  //   background: linear-gradient(180deg, #EEF5FC 0%, #F1F7FE 71.91%, rgba(243, 249, 255, 0) 100%);
  //   width: 100%;
  //   height: 100%;
  //   // top: 50%;
  //   // left: 50%;
  //   // transform: translate(-50%,-50%);
  //   // width: 1000%;
  //   position: absolute;
  //   z-index: -99;
  // }
  
  .part33-eachSection{
    // width: 100%;
    height: 100%;
    padding: 26px;
    margin-top: 26px;
    .bottom-pic-container{
      position: relative;
      height:420px;
      width: 100%;
    }
    .info-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      .info-icon{
        position: relative;
        left: -10px;
        .pic{
          width: 80px;
          height: 80px;
        }
      }
      .info-title{
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-size: 26px;
        line-height: 32px;
        color: #062440;
        flex: none;
        order: 0;
        flex-grow: 0;
        text-align: left;
      }
      .info-para{
        font-family: 'Poppins';
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        color: #314455;
        flex: none;
        order: 0;
        flex-grow: 0;
        text-align: left;
      }
      .line{
        width: 100%;
        height: 1px;
        background-color: #E6EBF2;
      }
      .bullet-points{
        padding: 0 9px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 22px;
        .bullet-point{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          // width: 100%;
          gap: 20px;
          .dot{
            min-width: 10px;
            max-width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #1890ff;
          }
          .text{
            text-align: left;
            // width: 100%;
            font-family: 'Poppins';
            font-size: 12px;
            line-height: 18px;
            color: #314455;
            flex: none;
            order: 0;
            flex-grow: 0;
            width:fit-content
          }
        }

      }
    }
  }
  .extra-adjust{
    position:relative;
    top:-10px;
  }
  
  .simplify-control{
  }
  .simplifyPic{
    position: absolute;
    width: 375px;
    height: 798px;
    top: -140px;
    right: -30px;
  }
  .simplifyPic2{
    position: absolute;
    width: 233px;
    height: 260px;
    left: 30px;
    top: 190px;
    // left: -18px;
  }
  .stayPic{
    position: absolute;
    width: 371px;
    height: 280px;
    left: -25px;
    top: 40px;
  }
  .stayPic1{
    position: absolute;
    width: 299px;
    height: 240px;
    left: 0px;
    bottom: 35px;
  }
  .stayPic2{
    position: absolute;
    width: 179px;
    height: 210px;
    right: 0px;
    bottom: 15px;
  }
  .stayInControl{
    // 
  }
  .smartAutomated{
    height: 520px !important;
  }
  // 蓝色的球
  .smartPic3{
    position: absolute;
    width: 326.54px;
    height: 325.49px;
    z-index: 0;
    left: 0;
    top: 70px;
  }
  .smartPic{
    position: absolute;
    width: 309px;
    height: 536px;
    top: 20px;
    left: 0;
  }
  .smartPic1{
    position: absolute;
    width: 382px;
    height: 463px;
    top: 20px;
    right: -30px;
  }
}

// .part22{
//   width: 100%;
//   overflow: hidden;
// }

// .part2{
//   // overflow: hidden;
//   margin: auto;
//   padding:0 16px 16px 16px;
//   text-align: left;
//   .part2Title{
//     margin:0 10px 10px 10px;
//     flex-grow: 0;
//     font-family: Poppins-Bold;
//     font-size: 26px;
//     // font-weight: bold;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.23;
//     letter-spacing: normal;
//     text-align: left;
//     color: #062440;
//   }
//   .part2Content{
//     margin-left: 10px;
//     margin:0 10px 10px 10px;
//     // height: 60px;
//     flex-grow: 0;
//     font-family: Poppins;
//     font-size: 14px;
//     font-weight: normal;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.43;
//     letter-spacing: normal;
//     text-align: left;
//     color: #314455;
//   }
//   .part2Line{
//     margin:20px 10px 20px 10px;
//     // width: 323px;
//     height: 1px;
//     background-color: #e6ebf2;
//   }
//   .part2subLine{
//     margin:0 10px 0 10px;
//     display: flex;
//     .dog{
//       margin:4px 10px 28px 0;
//       border-radius: 50%;
//       width: 10px;
//       height: 10px;
//       flex-grow: 0;
//       background-color: #1890ff;
//     }
//     .text{
//       // height: 18px;
//       margin-bottom: 22px;
//       flex-grow: 1;
//       font-family: Poppins;
//       font-size: 12px;
//       font-weight: normal;
//       font-stretch: normal;
//       font-style: normal;
//       line-height: 1.5;
//       letter-spacing: normal;
//       text-align: left;
//       color: #314455;
//     }
//   }
// }

.pic{
  width: 80px;
  height: 80px;
}

.ClientTest{
  margin: auto;
  width: 100%;
  display: block;
  // height: 600px;
  margin-bottom: 50px;
  // overflow-x: hidden;
  .title{
    margin:0 26px 0px 26px;
    // margin-top:50px;
    // margin-bottom: 15px;
    // width: 303px;
    height: 84px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 36px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
  }
  img{
    // overflow: hidden;
    display:block;
    margin: auto;
    margin-top:15px;
    margin-bottom: 25px;
    width: 87%;
    // height: 327.6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    // gap: 20px;
    // margin: 5px 0 24.4px 0;
    // padding: 20px;

    border-radius: 5px;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05), 0 15px 30px 0 rgba(0, 0, 0, 0.07);
    // border: solid 1px #e6ebf2;
    background-color: #fff;
  }
  // transition{-webkit-backface-visibility: hidden;}
  .dogs{
    margin: auto;
    width: 70px;
    height: 10px;
    display: flex;
    .dogBlue{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #1890ff;
      display: inline-block;
      margin:0 10px 0 10px;
    }
    .dogWhite{
      width: 10px;
      height: 10px;
      background-color: #d8d8d8;
      border-radius: 50%;
      display: inline-block;
      margin:0 10px 0 10px;
    }
  }
}

// .card-place{
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   gap: 40px;
// }
.testimon{
  max-width: 428px;
  min-width: 320px;
  margin:0 auto;
  // display: block;
  // margin: auto;


  .title{
    width: 240px;
    height: 84px;
    padding: 0 26px;
    // display: inline-block;
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-size: 36px;
    line-height: 42px;
    color: #062440;
    text-align: left;
    // margin:auto;
  }
}

.main-card-section{
  margin: 0 26px;
  margin-top: 30px;
  z-index: 0;
  // width: 100%;
  min-height: 316px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  background:#FFFFFF;

  border: 1px solid #FFFFFF;
  // box-shadow: 0px 2px 8px rgba(24, 144, 255, 0.16);
  box-shadow: 0px 18px 99px rgba(24, 144, 255, 0.04), 0px 4.02054px 22.1129px rgba(24, 144, 255, 0.0238443), 0px 1.19702px 6.5836px rgba(24, 144, 255, 0.0161557);
  // box-shadow: 0px 18px 99px rgba(24, 144, 255, 0.04), 0px 4.02054px 22.1129px rgba(24, 144, 255, 0.0238443), 0px 1.19702px 6.5836px rgba(24, 144, 255, 0.0161557);
  border-radius: 10px;
}


.brand-name-section{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;
  gap: 14px;
  .company-name{
    // margin-left: 14px;
    // height: 20px;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
  }
  .name{
    height: 20px;
    font-family: 'Poppins-semibold';
    font-size: 14px;
    line-height: 20px;
    color: #041A2F;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .author{
    height: 18px;
    font-family: 'Poppins';
    font-size: 12px;
    line-height: 18px;
    color: #062440;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .star-section{
    // display: flex;
    // flex-direction: row;
    // justify-content: flex-end;
    // width: 100%;
  }

  .star{
    width:86px;
    height:14px;
  }
}


.logo-pic{
  width: 64px;
  height: 64px;
}



.comment-section{
  width: 100%;
  text-align: left;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #314455;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}



.swiper-container {
        width: 100%;
        height: 100%;
        // margin: 20px auto;
        padding-bottom: 24px;
    }
  .swiperImg{
    margin: auto;
	  margin-top:0;
    width: 80%;
	  height:auto;
    // margin-bottom: 24px;
  }
  .swiper-pagination{
    margin-top: 0px!important;
    transform: scale(0.5);
  }

@keyframes slidein {
  from {
    margin-left: 150%;
    width: 87%;
    // height: auto;
    // width: 100%;
  }

  to {
    margin-left: 6.5%;
    // width: 100%;
  }
}

@keyframes slideout {
  from {
    margin-left: 6.5%;
    width: 87%;
  }

  to {
    margin-left: -100%;
  }
}

// .slide-fade-enter-active {
        //   display: -webkit-box;
        // display: -moz-box;
        // display: -webkit-flex;
        // display: -ms-flexbox;
        // display: flex;
        // -moz-transition-duration: 0s linear;
        // -webkit-transition-duration: 0s linear;
        // -o-transition-duration: 0s linear;
        // transition-duration: 0s linear;
// }
// .fade-leave-active /* .fade-leave-active below version 2.1.8 */ {
  // animation:slideout 0.3s
// }


// .bottom_button_section{
//   width: 0 auto;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   margin-top: 40px;
//   // padding: 10px 16px;

//   .bottom_button{
//     background: #1890FF;
//     width: 176px;
//     height: 42px;
//     border-radius: 5px;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     .bottom_text{
//       color: #fff;
//       font-family: 'Poppins-semibold';
//       line-height: 22px;
//       font-size: 16px;
//       width: 112px;
//       height: 22px;
//     }
//     .vetor{
//       font-weight: bold;
//       font-size: 16px;
//     }
//   }
// }


.videoMain{
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 888;
    text-align: center;
    display: block;
}

.videoPosi{
  position: relative;
  // margin: 0px auto;
  width: 100%;
  top: 50%;
  left: 50%;
  // max-width: 3140px;
  opacity: 1;
  transform: translate(-50%, -50%);
}

.videoContent{
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-start;
}
.close-icon{

    position: absolute;
    background-color: #1890FF;
    border: 1px solid #FFFFFF;
    font-family: "Poppins-Bold";
    font-size: 16px;
    line-height: 22px;
    font-size: 16px;
    bottom: -10vh;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: center;
    padding: 8px 16px;
    right: 50%;
    transform: translateX(50%);
}

.effortlessButton{
    width: fit-content;
    height: 22px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    border-radius: 5px;
    background-color: #1890FF;
    margin: 0 auto;
    margin-top: 9px;

    .part1ButtonText{
      display: flex;
      height: 22px;
      flex-grow: 0;
      font-family: Poppins-semibold;
      font-size: 14px;
      // font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      // line-height: 1.38;
      line-height: 20px;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
    .IDpart1ButtonText{
      width: 157px;
    }
  }

.totalButtonSection{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between
}

// .idWhatsApp{
//   // justify-self: flex-end
// }
  
.whatsAppIcon{
  // cursor: pointer;
  width: 40px;
  height: 40px;
}

.videoButton{
  // margin-top: 20px;
  width: 140px;
  height: 22px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // gap: 10px;
  padding: 10px 16px;
  border-radius: 5px;
  background-color: #FFFFFF;
  border: 1px solid #314455;
  cursor: pointer;
  .videoButtonText{
    display: flex;
    height: 22px;
    flex-grow: 0;
    font-family: Poppins-semibold;
    font-size: 14px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    // letter-spacing: normal;
    text-align: center;
    color:#314455;
    white-space: nowrap;

    path{
      fill: #314455;
    }
  }
  &:hover{
    border: 1px solid #062440;
    background-color: #F7F8FA;;
    .videoButtonText{
      color: #062440;
    }
    path{
      fill: #062440;
    }
  }
  &:active{
    border: 1px solid #062440;
    background-color: #F7F8FA;;
    .videoButtonText{
      color: #062440;
    }
    path{
      fill: #062440;
    }
  }

  // &:hover{
  //   border: 1px solid #1890FF;
  //   background-color: #FFFFFF;
  //   .videoButtonText{
  //     color: #096DD9;
  //   }
  //   path{
  //     fill: #096DD9;
  //   }
  // }
}

</style>

<style lang="scss">
.part25ArcaniteFor{
  .tab-differentCustomer{
    // padding:0 26px;
    .el-tabs--border-card{
      border: none;
      box-shadow:none;
    }
    .el-tabs__header{
      color:#062440;
      height: 56px;
      // width:384px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom:none;
      background-color: #FFF;
      gap: 12px;
    }
    .el-tabs__nav-wrap .is-top{
      background: rgba(247, 248, 250, 0.6);
      // background-color: rgba(247, 248, 250, 0.6);
      padding:10px 12px;
      border-radius:4px;
    }
    .el-tabs__nav-scroll{
      // display:none;
      overflow:unset;
      width:fit-content;
    }
    .el-tabs__nav{
      gap: 12px;
    }
    .el-tabs__nav .is-top{
      display:flex;
      justify-content: center;
      align-items:center;
      min-height: 36px;
    }
    .is-top{
      // width:200px !important;
      display:flex;
      justify-content:center;
      align-items:center;
      margin:0;
    }
    .is-active{
      display:flex;
      justify-content:center;
      align-items:center;
      background-color: #1890FF !important;
      color:#FFFFFF !important;
      // margin:0 12px;
    }
    .el-tabs__item{
      color: #314455;
      min-width: 150px;
      max-width: 152px;
      min-height: 27px;
      max-height: 29px;
      font-family: 'Poppins';
      font-style: normal;
      // width: 164px;
      // height: 36px;
      border-radius: 4px;
      // padding:10px 12px;
      font-size: 14px;
      line-height: 20px;
    }
    .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active{
      border:none;
    }
    .el-tabs__content{
      padding:0;
    }

    // .el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled):hover {
    //   color:#67788C !important;
    // }
  }
}
</style>


